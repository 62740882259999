<app-menu></app-menu>
<app-back-header title="Events" back="" theme="charcoal"></app-back-header>
<div class="wrapper">
    <p><i><b>We have many programs running around Australia, see which programs are running close to you!</b></i><br>
   
   
    <h2>Maker Heals VIC</h2>

    <p><b>For more details:</b> Contact Ledua at 0403 775 729<br>
        <br>

    <h2>Maker Heals SQLD</h2>
        
    <p><b>For more details:</b> Contact Christine at 0412 858 038<br>
        <br>

    <h2>Maker Heals WA</h2>

    <p><i>10-day Intensive<br /></i>
    <b>When: </b>April 27 - May 6, 2025<br/>
    <b>Where:</b>Shalem Health Retreat<br/></p>
    <p><b>For more details:</b> Contact Jo Laing (Manager) at 0412 400 494 for admin inquiries or Noel Mondejar (Health Director) at 0435 313 370 for health-related inquiries</p>

    <p><i>10-day Intensive<br /></i>
    <b>When: </b>September 7 - 16, 2025<br/>
    <b>Where:</b>Shalem Health Retreat<br/></p>
    <p><b>For more details:</b> Contact Jo Laing (Manager) at 0412 400 494 for admin inquiries or Noel Mondejar (Health Director) at 0435 313 370 for health-related inquiries</p>

    <p><i>10-day Intensive<br /></i>
    <b>When: </b>November 2 - 11, 2025<br/>
    <b>Where:</b>Shalem Health Retreat<br/></p>
    <p><b>For more details:</b> Contact Jo Laing (Manager) at 0412 400 494 for admin inquiries or Noel Mondejar (Health Director) at 0435 313 370 for health-related inquiries</p>


    <h2>Maker Heals SA</h2>

  <p><b>For more details:</b> Contact Sue Wilson at 0400 640 122 or susanewilson59@gmail.com<br>
</div>
<br>


<app-footer></app-footer>