<app-menu></app-menu>
<app-back-header title="Contact Us" [back]="backLink" theme="charcoal"></app-back-header>
<app-section theme="lightGrey" class="phones">
    <p style="color: #fff; background-color:#53875B; text-align:center; padding:10px; width:100%;"><b>NOTICE:</b><br>Due to the holiday period and busy schedules with Youth Rush and Music Camp programs, we may be slow in responding to enquiries between now and the end of January.</p>
    <h2>The Maker Heals - Ledua Brooks</h2>
    <p class="ilb" style="font-size: 30px;">
        <app-icon style="width: 30px;height: 30px; margin-right: 20px;">phone</app-icon>
        <span>0403 775 729</span>
    </p>
    <h2>Madison Missions - Danny Brooks</h2>
    <p class="ilb" style="font-size: 30px;">
        <app-icon style="width: 30px;height: 30px; margin-right: 20px;">phone</app-icon>
        <span>0403 775 774</span>
    </p>
    <h2>Or scroll down to send a message:</h2>
</app-section>
<div id="form"></div>
<app-share></app-share>
<app-subscribe></app-subscribe>
<app-footer></app-footer>